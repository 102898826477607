import React, {Component} from 'react'
import {appDatabasePrimaryFunctions, base} from '../../base';
import {Redirect} from 'react-router-dom'
import {confirmAlert} from '../utils/react-confirm-alert';
import {detectHowManyCodesEntered, getURLParameter, validateEmail} from '../utils/HelpfulFunction';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import '../../styles/css/ConfirmAlertCustom.css';
import '../../styles/css/Home.css';
import '../../styles/css/main.css';
import '../../styles/css/CreatorAwards.css';
import Loading from "../utils/Loading";
import imageCompression from 'browser-image-compression';

async function signUpUserFunction(userObject) {
  let url = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/api/signUpUserFunction`;
  if (process.env.NODE_ENV === "development") {
    url = `http://localhost:5001/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/us-central1/api/signUpUserFunction`;
  }

  const formData = new FormData();

  // Assuming userObject.photoToSave is the file object to be uploaded
  if (userObject.photoToSave) {
    formData.append('photoToSave', userObject.photoToSave);
  }

  // Append other userObject properties to the formData if necessary
  for (const key in userObject) {
    if (key !== 'photoToSave') {
      formData.append(key, userObject[key]);
    }
  }

  try {
    const response = await fetch(url, {
      method: 'POST',
      body: formData, // Sending the formData
      // Don't set 'Content-Type' header, the browser will set it with the correct boundary parameter
    });

    return await response.json();
  } catch (error) {
    console.error("Error in signUpUserFunction:", error);
    throw error; // Rethrow or handle error as needed
  }
}

class Login extends Component {
    constructor(props) {
        super(props);
        this.fileInputRef = React.createRef();
        let userEmail;
        try {
          userEmail = props.appStorage.getItem('userEmail') || false;
        } catch (e) {
          userEmail = false
        }
        this.state = {
          redirect: false,
          tenantVariables: {},
          inputtedEmail: '',
          inputtedName: '',
          inputtedOtherName: '',
          inputtedZipCode: '',
          inputtedCity: '',
          inputtedStreet: '',
          inputtedState: '',
          inputtedFullAddress: '',
          inputtedPhoneNumber: '',
          fanResponseToCollectTextInput: '',
          howYouHeard: '',
          userEmail: userEmail,
          imageWidth: 0,
          loading: true
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.toggle = this.toggle.bind(this);
        this.toggleRules = this.toggleRules.bind(this);
        this.toggleMlbRules = this.toggleMlbRules.bind(this);
    }

    componentDidMount() {
      this.currentGameKeyRef = base.listenTo(`currentGame/id`, {
        context: this,
        then(key){
          let htmlEmailParameter = getURLParameter("email");
          if(htmlEmailParameter && key){
            let base64EncodedEmail = btoa(htmlEmailParameter);
            base.fetch('userGameHistory/'+ base64EncodedEmail+"/"+key, {
              context: this,
              then(data){
                if(typeof data === 'string'){
                  this.props.setPassed(true);
                  this.continueSignInProcess(htmlEmailParameter, "", "", false,"")
                }
              }
            })
          }
        }
      });
      this.tenantRulesRef = base.bindToState(`tenantRules`, {
        context: this,
        state: 'tenantRules',
      });
      this.props.loadPage();
    }

    componentWillUnmount() {
      base.removeBinding(this.tenantRulesRef);
      base.removeBinding(this.currentGameKeyRef);
    }

    skipLogin(){
      const tenantVariables = this.state.tenantVariables;
      const stringConstants = this.props.stringConstants || {};
      const confirmMandatoryRef = this.refs.agree_to_rules_regs.checked;
      if(tenantVariables.mandatoryTermsAndConditions && !confirmMandatoryRef){
        confirmAlert({
          variables: tenantVariables,
          title: stringConstants.ERRORTEXT,
          message: stringConstants.NOAGREERULESANDREGS,
          buttons: [
            {
              label: stringConstants.OKTEXT,
            }
          ]
        })
        return;
      }
      const randomElement = Math.floor((Math.random() * 100000) + 1);
      const sessionTokenString = Date.now().toString() + randomElement.toString();
      this.props.appStorage.setItem("tempToken", sessionTokenString);
      this.setState({
        redirect: true,
      })
    }

    toggle() {
      this.setState({
        modal: !this.state.modal
      });
    }

    toggleSupport(){
      this.setState({
        modalSupport: !this.state.modalSupport,
      });
    }

    sanitizeEmail(emailAddress){
      //get part of email before @ sign, this has different rules then text after the @
      const firstPartOfEmail = emailAddress.split('@')[0];
      //get rest of email to recombine later
      const secondPartOfEmail = emailAddress.split('@')[1];
      //Removes periods because on some mail clients they can be moved around and we will treat them like a seperate email
      const removedPeriodsFirstPartOfEmail = firstPartOfEmail.replace(/\./g, "");
      //Check if there is a plus in the email
      const splitPluses = removedPeriodsFirstPartOfEmail.split('+');
      let finalFirstPart;
      if (splitPluses.length > 1)
      {
        //if there is a plus cut all the nonsense after the plus, this is important because some email clients allow you to put whatever you want after the plus
        finalFirstPart = splitPluses[0];
      }
      else
      {
        //if no plus continue with periods removed from first part of email
        finalFirstPart = removedPeriodsFirstPartOfEmail;
      }
      //recombine email to make the sanitized version
      const sanitizedEmail = finalFirstPart + "@" + secondPartOfEmail;
      //change to upper so people can sneak by case on me
      return sanitizedEmail.toLowerCase();
    }

    isValidDate(s) {
      const bits = s.split('-');
      const d = new Date(bits[0], bits[1] - 1, bits[2]);
      return d && (d.getMonth() + 1) === parseInt(bits[1]);
    }

    isCanadianZipCode(postalCode){
      const regex = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;
      return regex.test(postalCode.toUpperCase());
    }

    handleChange(event) {
      const tenantVariables = this.props.variables || {};
      const stringConstants = this.props.stringConstants || {};
      const { type, name, value, files } = event.target;

      if (type === 'file') {
        const file = files[0]; // Assuming single file upload
        if (!file) return; // No file was uploaded
        // Check if the file is an image
        if (file.type.startsWith('image/')) {
          const options = {
            maxSizeMB: 10, // Max size of the file in MB
            maxWidthOrHeight: 1920, // Max width or height of the image
            useWebWorker: true // Enable multi-threading if supported
          };

          imageCompression(file, options)
              .then(compressedFile => {
                this.setState({ [name]: compressedFile });
              })
              .catch(error => {
                console.error('Compression error:', error);
              });
        } else {
          confirmAlert({
            variables: tenantVariables,
            title: stringConstants.MISSINGINFOTEXT,
            message: 'Please upload only image files.',
            buttons: [
              {
                label: stringConstants.OKTEXT,
              }
            ]
          })
        }
      } else {
        // Handle regular text inputs
        this.setState({ [name]: value });
      }
    }

    handleDateChange(event) {
      let value = event.target.value;
      if(event.target.name === "month"){
        if(value > 12){
          value = "12"
        }
      } else if(event.target.name === "day"){
        if(value > 31){
          value = "31"
        }
      } else if(event.target.name === "year"){
        if(value > new Date().getFullYear()){
          value = new Date().getFullYear().toString()
        }
      }
      this.setState({[event.target.name]: value});
    }

    handleSubmit(event){
      event.preventDefault();
      const stringConstants = this.props.stringConstants || {};
      const inputtedEmail = this.state.inputtedEmail.trim();
      let htmlParameterEmail = getURLParameter("email");
      const tenantVariables = this.props.variables;
      if(htmlParameterEmail && !validateEmail(htmlParameterEmail)){
        htmlParameterEmail = null
      }
      const inputtedName = this.state.inputtedName.trim();
      const inputtedOtherName = this.state.inputtedOtherName.trim();
      const year = this.state.year;
      let month = this.state.month;
      let day = this.state.day;
      let inputtedBirthday = localStorage.getItem('birthday') || "";
      const inputtedZipCode = this.state.inputtedZipCode.trim();
      const inputtedStreet = this.state.inputtedStreet.trim();
      const inputtedCity = this.state.inputtedCity.trim();
      const inputtedState = this.state.inputtedState.trim();
      const inputtedFullAddress = this.state.inputtedFullAddress.trim();
      const inputtedPhoneNumber = this.state.inputtedPhoneNumber.trim();
      const inputtedSocialHandle = this.state.inputtedSocialHandle || "";
      const customDropdownInput = this.state.customDropdownInput || "";
      const textInputOne = this.state.textInputOne || "";
      const textInputTwo = this.state.textInputTwo || "";
      const yesExplanationInput = this.state.yesExplanationInput || "";
      const fanResponseToCollectTextInput = this.state.fanResponseToCollectTextInput.trim();
      const confirmMandatoryRef = this.refs.agree_to_rules_regs.checked;
      const agree_to_promotionRef = this.refs.agree_to_promotion.checked;
      const agree_to_promotionRefTwo = this.refs.agree_to_promotion_two.checked;
      const agree_to_promotionRefThree = this.refs.agree_to_promotion_three.checked;
      const agree_to_promotionRefFour = this.refs.agree_to_promotion_four.checked;
      const isMlbApp =  process.env.REACT_APP_IS_MLB_TEAM === "true";
      const noCollectingEmails = tenantVariables.doNotCollectEmail || isMlbApp;
      let yesNoQuestionAnswer = this.state.yesNoQuestionAnswer;
      let howYouHeard = this.state.howYouHeard;
      let photoToSave = this.state.photoToSave;
      if(tenantVariables.collectYesNoQuestion && !yesNoQuestionAnswer){
        confirmAlert({
          variables: tenantVariables,
          title: stringConstants.MISSINGINFOTEXT,
          message: "Please select either yes or no",
          buttons: [
            {
              label: stringConstants.OKTEXT,
            }
          ]
        })
        return;
      }
      if(tenantVariables.collectSocialHandle && (!inputtedSocialHandle || !inputtedSocialHandle.trim())){
        confirmAlert({
          variables: tenantVariables,
          title: stringConstants.MISSINGINFOTEXT,
          message: stringConstants.EMPTYLSOCIALTAGEERROR,
          buttons: [
            {
              label: stringConstants.OKTEXT,
            }
          ]
        })
        return;
      }
      if(inputtedEmail === "" && !htmlParameterEmail && !noCollectingEmails){
        confirmAlert({
          variables: tenantVariables,
          title: stringConstants.MISSINGINFOTEXT,
          message: stringConstants.EMPTYLEMAILERROR,
          buttons: [
            {
              label: stringConstants.OKTEXT,
            }
          ]
        })
        return;
      } else if(!noCollectingEmails && !validateEmail(inputtedEmail) && !htmlParameterEmail){
        confirmAlert({
          variables: tenantVariables,
          title: stringConstants.MISSINGINFOTEXT,
          message: stringConstants.UNABLETOREADEMAILTEXT,
          buttons: [
            {
              label: stringConstants.OKTEXT
            }
          ]
        })
        return;
      } else if(!noCollectingEmails && (tenantVariables.allowList || tenantVariables.blockList)){
        const lowerCaseEmail = inputtedEmail.toLowerCase();
        const emailDomain = lowerCaseEmail.split('@')[1];
        let allowListArray = [];
        let blockListArray = [];
        if(tenantVariables.allowList){
          allowListArray = tenantVariables.allowList.split(" ");
        }
        if(tenantVariables.blockList){
          blockListArray = tenantVariables.blockList.split(" ");
        }
        if(tenantVariables.allowList && allowListArray.indexOf(lowerCaseEmail) === -1 && allowListArray.indexOf(emailDomain) === -1){
          confirmAlert({
            variables: tenantVariables,
            title: stringConstants.EMAILEERRORHEADER,
            message: stringConstants.EMAILNOTALLOWEDERROR,
            buttons: [
              {
                label: stringConstants.OKTEXT
              }
            ]
          })
          return;
        } else if(tenantVariables.allowList && (allowListArray.indexOf(lowerCaseEmail) !== -1 || allowListArray.indexOf(emailDomain) === -1)) {
          console.log("Allow override")
        } else if(tenantVariables.blockList && (blockListArray.indexOf(lowerCaseEmail) !== -1 || blockListArray.indexOf(emailDomain) !== -1)){
          confirmAlert({
            variables: tenantVariables,
            title: stringConstants.EMAILEERRORHEADER,
            message: stringConstants.EMAILNOTALLOWEDERROR,
            buttons: [
              {
                label: stringConstants.OKTEXT
              }
            ]
          })
          return;
        }
      }

      const allowedAge = tenantVariables.allowedAge || 21;
      if(tenantVariables.collectBirthday && tenantVariables.formBirthday && (!year || !month || !day)){
        confirmAlert({
          variables: tenantVariables,
          title: stringConstants.MISSINGINFOTEXT,
          message: stringConstants.EMPTYLBIRTHDAYERROR,
          buttons: [
            {
              label: stringConstants.OKTEXT
            }
          ]
        })
        return;
      } else if(tenantVariables.collectBirthday && tenantVariables.formBirthday){
        if(year.length < 4){
          confirmAlert({
            variables: tenantVariables,
            title: stringConstants.MISSINGINFOTEXT,
            message: stringConstants.BIRTHDAYYEARBADFORMAT,
            buttons: [
              {
                label: stringConstants.OKTEXT
              }
            ]
          })
          return;
        }
        if(month && month.length < 2){
          month = "0" + month
        }
        if(day && day.length < 2){
          day = "0" + day
        }
        inputtedBirthday = year + "-" + month + "-" + day;
        if(isNaN(new Date(inputtedBirthday).getTime()) || !this.isValidDate(inputtedBirthday)){
          confirmAlert({
            variables: tenantVariables,
            title: stringConstants.MISSINGINFOTEXT,
            message: stringConstants.INVALIDDATEERROR,
            buttons: [
              {
                label: stringConstants.OKTEXT
              }
            ]
          })
          return;
        }
        const age = ~~((new Date().getTime() - +new Date(inputtedBirthday)) / (31557600000))
        if (age < allowedAge) {
          confirmAlert({
            variables: tenantVariables,
            title: stringConstants.INPUTERRORTITLE,
            message: stringConstants.NOTOLDENOUGHERROR,
            buttons: [
              {
                label: stringConstants.OKTEXT
              }
            ]
          })
          return;
        }
      }

      if(tenantVariables.collectName && inputtedName === ""){
        confirmAlert({
          variables: tenantVariables,
          title: stringConstants.MISSINGINFOTEXT,
          message: stringConstants.EMPTYLNAMEERROR,
          buttons: [
            {
              label: stringConstants.OKTEXT
            }
          ]
        })
        return;
      } else if(tenantVariables.collectName && (inputtedName === "" || inputtedName.split(' ').length <= 1 || inputtedName.length > 35)){
        confirmAlert({
          variables: tenantVariables,
          title: stringConstants.INPUTERRORTITLE,
          message: stringConstants.NOLASTNAMEERROR,
          buttons: [
            {
              label: stringConstants.OKTEXT
            }
          ]
        })
        return;
      }

      if(tenantVariables.collectOtherName && inputtedOtherName === ""){
        confirmAlert({
          variables: tenantVariables,
          title: stringConstants.MISSINGINFOTEXT,
          message: stringConstants.EMPTYLNAMEERROR,
          buttons: [
            {
              label: stringConstants.OKTEXT
            }
          ]
        })
        return;
      } else if(tenantVariables.collectOtherName && (inputtedOtherName === "" || inputtedOtherName.split(' ').length <= 1 || inputtedOtherName.length > 35)){
        confirmAlert({
          variables: tenantVariables,
          title: stringConstants.INPUTERRORTITLE,
          message: stringConstants.NOLASTNAMEERROR,
          buttons: [
            {
              label: stringConstants.OKTEXT
            }
          ]
        })
        return;
      }

      if(tenantVariables.collectPhoneNumber && inputtedPhoneNumber === ""){
        if(!tenantVariables.optionalPhoneNumber){
          confirmAlert({
            variables: tenantVariables,
            title: stringConstants.INPUTERRORTITLE,
            message: stringConstants.EMPTYPHONENUMBERTEXT,
            buttons: [
              {
                label: stringConstants.OKTEXT,
              }
            ]
          })
          return;
        }
      } else if(tenantVariables.collectPhoneNumber && !/^\d+$/.test(inputtedPhoneNumber)){
        confirmAlert({
          variables: tenantVariables,
          title: stringConstants.INPUTERRORTITLE,
          message: stringConstants.NOTAPHONENUMBERTEXT,
          buttons: [
            {
              label: stringConstants.OKTEXT,
            }
          ]
        })
        return;
      } else if(tenantVariables.collectPhoneNumber && inputtedPhoneNumber.length !== 10){
        confirmAlert({
          variables: tenantVariables,
          title: stringConstants.INPUTERRORTITLE,
          message: stringConstants.NOTAPHONENUMBERTEXT,
          buttons: [
            {
              label: stringConstants.OKTEXT,
            }
          ]
        })
        return;
      }

      if(tenantVariables.collectZipCode && inputtedZipCode === ""){
        confirmAlert({
          variables: tenantVariables,
          title: stringConstants.MISSINGINFOTEXT,
          message: stringConstants.EMPTYLZIPCODEERROR,
          buttons: [
            {
              label: stringConstants.OKTEXT
            }
          ]
        })
        return;
      } else if(tenantVariables.collectZipCode && !tenantVariables.doNotVerifyAmericanZipCode && !/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(inputtedZipCode)){
        confirmAlert({
          variables: tenantVariables,
          title: stringConstants.MISSINGINFOTEXT,
          message: stringConstants.NOTVALIDUSZIP,
          buttons: [
            {
              label: stringConstants.OKTEXT
            }
          ]
        })
        return;
      } else if (tenantVariables.collectZipCode && tenantVariables.doNotVerifyAmericanZipCode && tenantVariables.verifyCanadianZipCode && !this.isCanadianZipCode(inputtedZipCode)) {
        confirmAlert({
          variables: tenantVariables,
          title: stringConstants.MISSINGINFOTEXT,
          message: stringConstants.NOTVALIDCANADIANZIP,
          buttons: [
            {
              label: stringConstants.OKTEXT,
            }
          ]
        });
        return;
      }

      if(tenantVariables.collectTextInput && !fanResponseToCollectTextInput){
        confirmAlert({
          variables: tenantVariables,
          title: stringConstants.MISSINGINFOTEXT,
          message: stringConstants.NOFANRESPONSETEXT,
          buttons: [
            {
              label: stringConstants.OKTEXT
            }
          ]
        })
        return;
      }

      if(tenantVariables.collectYesNoQuestion && tenantVariables.collectYesExplanation && this.state.yesNoQuestionAnswer === "yes" && !yesExplanationInput){
        confirmAlert({
          variables: tenantVariables,
          title: stringConstants.MISSINGINFOTEXT,
          message: stringConstants.PLEASEFILLOUTTHEFORMTEXT,
          buttons: [
            {
              label: stringConstants.OKTEXT
            }
          ]
        })
        return;
      }

      if(tenantVariables.collectCustomDropdown && !customDropdownInput){
        confirmAlert({
          variables: tenantVariables,
          title: stringConstants.MISSINGINFOTEXT,
          message: stringConstants.PLEASEFILLOUTTHEFORMTEXT,
          buttons: [
            {
              label: stringConstants.OKTEXT
            }
          ]
        })
        return;
      }

      if(tenantVariables.collectTextInputOne && !textInputOne){
        confirmAlert({
          variables: tenantVariables,
          title: stringConstants.MISSINGINFOTEXT,
          message: stringConstants.PLEASEFILLOUTTHEFORMTEXT,
          buttons: [
            {
              label: stringConstants.OKTEXT
            }
          ]
        })
        return;
      }

      if(tenantVariables.collectTextInputTwo && !textInputTwo){
        confirmAlert({
          variables: tenantVariables,
          title: stringConstants.MISSINGINFOTEXT,
          message: stringConstants.PLEASEFILLOUTTHEFORMTEXT,
          buttons: [
            {
              label: stringConstants.OKTEXT
            }
          ]
        })
        return;
      }

      if(tenantVariables.collectPhoto && !photoToSave){
        confirmAlert({
          variables: tenantVariables,
          title: stringConstants.MISSINGINFOTEXT,
          message: stringConstants.NOPHOTOTOSAVETEXT,
          buttons: [
            {
              label: stringConstants.OKTEXT
            }
          ]
        })
        return;
      }

      if(tenantVariables.collectHowYouHeard && !tenantVariables.optionalHowYouHeard && !howYouHeard){
        confirmAlert({
          variables: tenantVariables,
          title: stringConstants.MISSINGINFOTEXT,
          message: stringConstants.EMPTYHOWYOUHEARDERROR,
          buttons: [
            {
              label: stringConstants.OKTEXT
            }
          ]
        })
        return;
      }

      if(tenantVariables.collectFullAddress && inputtedFullAddress === ""){
        confirmAlert({
          variables: tenantVariables,
          title: stringConstants.MISSINGINFOTEXT,
          message: stringConstants.EMPTYFULLADDRESSERROR,
          buttons: [
            {
              label: stringConstants.OKTEXT
            }
          ]
        })
        return;
      }

      if(tenantVariables.collectStreet && inputtedStreet === ""){
        confirmAlert({
          variables: tenantVariables,
          title: stringConstants.MISSINGINFOTEXT,
          message: stringConstants.EMPTYSTREETERROR,
          buttons: [
            {
              label: stringConstants.OKTEXT
            }
          ]
        })
        return;
      }

      if(tenantVariables.collectCity && inputtedCity === ""){
        confirmAlert({
          variables: tenantVariables,
          title: stringConstants.MISSINGINFOTEXT,
          message: stringConstants.EMPTYCITYERROR,
          buttons: [
            {
              label: stringConstants.OKTEXT
            }
          ]
        })
        return;
      }

      if(tenantVariables.collectState && inputtedState === ""){
        confirmAlert({
          variables: tenantVariables,
          title: stringConstants.MISSINGINFOTEXT,
          message: stringConstants.EMPTYSTATEERROR,
          buttons: [
            {
              label: stringConstants.OKTEXT
            }
          ]
        })
        return;
      }

      if(!tenantVariables.noMandatoryTermsAndConditions && !confirmMandatoryRef){
        confirmAlert({
          variables: tenantVariables,
          title: stringConstants.MISSINGINFOTEXT,
          message: stringConstants.NOAGREERULESANDREGS,
          buttons: [
            {
              label: stringConstants.OKTEXT
            }
          ]
        })
        return;
      }
      // Play with ' + this.state.inputedEmail + '?'
      if(noCollectingEmails){
        this.continueSignInProcess(inputtedEmail, inputtedZipCode, inputtedName, agree_to_promotionRef, inputtedBirthday, inputtedPhoneNumber, agree_to_promotionRefTwo, agree_to_promotionRefThree, agree_to_promotionRefFour, fanResponseToCollectTextInput, inputtedFullAddress, inputtedSocialHandle, yesNoQuestionAnswer, howYouHeard, inputtedOtherName, inputtedStreet, inputtedCity, inputtedStreet, inputtedState, photoToSave, yesExplanationInput, customDropdownInput, textInputOne, textInputTwo)
        return
      }
      if(!htmlParameterEmail) {
        confirmAlert({
          variables: tenantVariables,
          title: stringConstants.CONFIRMEMAILTEXT,
          message: inputtedEmail,
          buttons: [
            {
              label: stringConstants.NODISMISSBUTTONTEXT,
            },
            {
              label: stringConstants.YESCONFIRMBUTTONTEXT,
              onClick: async () => {
                this.setState({
                  loading:true
                })
                this.continueSignInProcess(inputtedEmail, inputtedZipCode, inputtedName, agree_to_promotionRef, inputtedBirthday, inputtedPhoneNumber, agree_to_promotionRefTwo, agree_to_promotionRefThree, agree_to_promotionRefFour, fanResponseToCollectTextInput, inputtedFullAddress, inputtedSocialHandle, yesNoQuestionAnswer, howYouHeard, inputtedOtherName, inputtedCity, inputtedStreet, inputtedState, photoToSave, yesExplanationInput, customDropdownInput, textInputOne, textInputTwo)
              }
            }
          ]
        })
      } else {
        this.props.setPassed(true);
        this.continueSignInProcess(htmlParameterEmail, inputtedZipCode, inputtedName, agree_to_promotionRef, inputtedBirthday, inputtedPhoneNumber, agree_to_promotionRefTwo, agree_to_promotionRefThree, agree_to_promotionRefFour, fanResponseToCollectTextInput, inputtedFullAddress, inputtedSocialHandle, yesNoQuestionAnswer, howYouHeard, inputtedOtherName, inputtedCity, inputtedStreet, inputtedState, photoToSave, yesExplanationInput, customDropdownInput, textInputOne, textInputTwo);
      }
    }

    async continueSignInProcess(inputtedEmail, inputtedZipCode, inputtedName, agree_to_promotionRef, inputtedBirthday, inputtedPhoneNumber, agree_to_promotionRefTwo, agree_to_promotionRefThree, agree_to_promotionRefFour, fanResponseToCollectTextInput, inputtedFullAddress, inputtedSocialHandle, yesNoQuestionAnswer, howYouHeard, inputtedOtherName, inputtedCity, inputtedStreet, inputtedState, photoToSave, yesExplanationInput, customDropdownInput, textInputOne, textInputTwo){
      let base64EncodedEmail = appDatabasePrimaryFunctions.ref().push().key;
      let userId = getURLParameter("userid");
      const isMlbApp =  process.env.REACT_APP_IS_MLB_TEAM === "true";
      const tenantVariables = this.props.variables || {};
      const stringConstants = this.props.stringConstants || {};
      let sanitizedEmail = "";
      if(inputtedEmail){
        try {
          sanitizedEmail = this.sanitizeEmail(inputtedEmail);
        } catch (e) {
          console.log(e)
        }
        base64EncodedEmail = btoa(inputtedEmail);
      } else if(userId){
        base64EncodedEmail = userId;
      } else if(!userId && isMlbApp) {
        confirmAlert({
          variables: this.props.variables,
          title: stringConstants.LOGINERROR,
          message: stringConstants.MLBLOGINERROR,
          buttons: [
            {
              label: stringConstants.OKTEXT,
            }
          ]
        })
        return
      }
      const userObject = {};
      userObject['lastSignIn'] = new Date().getTime();
      userObject['email'] = inputtedEmail;
      userObject['sanitizedEmail'] = sanitizedEmail;
      userObject['uid'] = base64EncodedEmail;
      if(inputtedZipCode){
        userObject['zipCode'] = inputtedZipCode;
      }
      if(inputtedName){
        userObject['name'] = inputtedName;
      }
      if(yesExplanationInput){
        userObject['yesExplanation'] = yesExplanationInput;
      }
      if(customDropdownInput){
        userObject['customDropdownInput'] = customDropdownInput;
      }
      if(textInputOne){
        userObject['textInputOne'] = textInputOne;
      }
      if(textInputTwo){
        userObject['textInputTwo'] = textInputTwo;
      }
      if(inputtedOtherName){
        userObject['otherName'] = inputtedOtherName;
      }
      if(inputtedFullAddress){
        userObject['address'] = inputtedFullAddress;
      }
      if(inputtedStreet){
        userObject['street'] = inputtedStreet;
      }
      if(inputtedCity){
        userObject['city'] = inputtedCity;
      }
      if(inputtedState){
        userObject['state'] = inputtedState;
      }
      if(yesNoQuestionAnswer){
        userObject['yesNoQuestions'] = yesNoQuestionAnswer;
      }
      if(howYouHeard){
        userObject['howYouHeard'] = howYouHeard;
      }
      if(photoToSave){
        userObject['photoToSave'] = photoToSave;
      }
      userObject['optIn'] = agree_to_promotionRef === 'true' || agree_to_promotionRef === true || false;
      userObject['optInTwo'] = agree_to_promotionRefTwo  === 'true' || agree_to_promotionRefTwo === true || false;
      userObject['optInThree'] = agree_to_promotionRefThree === 'true' || agree_to_promotionRefThree === true || false;
      userObject['optInFour'] = agree_to_promotionRefFour === 'true' || agree_to_promotionRefFour === true || false;
      if(fanResponseToCollectTextInput){
        userObject['fanResponseToCollectTextInput'] = fanResponseToCollectTextInput;
      }
      if(inputtedBirthday){
        userObject['birthday'] = inputtedBirthday;
      }
      if(inputtedPhoneNumber){
        userObject['phoneNumber'] = inputtedPhoneNumber;
      }
      if(inputtedSocialHandle){
        userObject['socialHandle'] = inputtedSocialHandle;
      }
      const vm = this;
      if(tenantVariables.sanitizeEmails && sanitizedEmail && inputtedEmail){
        inputtedEmail = sanitizedEmail;
        base64EncodedEmail = btoa(sanitizedEmail)
        userObject['uid'] = base64EncodedEmail;
      }
      this.setState({loading: true})
      if(photoToSave){
        try {
          const signUpResponse = await signUpUserFunction(userObject);
          if(signUpResponse.error){
            this.setState({loading: false});
            confirmAlert({
              variables: this.props.variables,
              title: stringConstants.LOGINERROR,
              message: signUpResponse.error,
              buttons: [
                {
                  label: stringConstants.OKTEXT,
                }
              ]
            })
            return;
          }
          console.log(signUpResponse)
        } catch (e) {
          this.setState({loading: false})
          if (err) {
            alert(stringConstants.UNKNOWNERROROCCURED);
            return;
          } else {
            alert(stringConstants.UNABLETOLOGYOUINTEXT);
            return;
          }
        }
      } else {
        try {
          await base.update('users/' + base64EncodedEmail, {
            data: userObject,
            context: this
          });
        } catch (err) {
          this.setState({loading: false})
          if (err) {
            alert(stringConstants.UNKNOWNERROROCCURED);
            return;
          } else {
            alert(stringConstants.UNABLETOLOGYOUINTEXT);
            return;
          }
        }
      }
      if(base64EncodedEmail){
        const srcCode = getURLParameter("srccode");
        const utm_source = getURLParameter("utm_source");
        const utm_medium = getURLParameter("utm_medium");
        const utm_campaign = getURLParameter("utm_campaign");

        const updates = {};
        updates['users/' + base64EncodedEmail + '/signUpTime'] = new Date().getTime();
        updates['users/' + base64EncodedEmail + '/srcCode'] = srcCode;
        updates['users/' + base64EncodedEmail + '/uid'] = base64EncodedEmail;
        updates['users/' + base64EncodedEmail + '/email'] = inputtedEmail;
        updates['users/' + base64EncodedEmail + '/lastSignIn'] = new Date().getTime();
        updates['users/' + base64EncodedEmail + '/utmSource'] = utm_source;
        updates['users/' + base64EncodedEmail + '/utmMedium'] = utm_medium;
        updates['users/' + base64EncodedEmail + '/utmCampaign'] = utm_campaign;
        await appDatabasePrimaryFunctions.ref().update(updates)
            .catch(function(error) {
              console.log(error);
            });
      }
      this.setState({loading: false})
      const emailToSet = inputtedEmail || base64EncodedEmail;
      vm.props.appStorage.setItem('userEmail', emailToSet);
      vm.props.setCurrentUser();
      vm.setState({ redirect: true });
    }

    toggleRules() {
      this.setState({
        modalRules: !this.state.modalRules,
      });
    }

    toggleMlbRules(){
      this.setState({
        modalMlbRules: !this.state.modalMlbRules,
      });
    }

    handleBirthdayFocus(){
      this.setState({
        birthdayFocused: true
      }, ()=> {
        document.getElementById('year').placeholder ="YYYY"
        document.getElementById('day').placeholder = "DD"
        document.getElementById('month').placeholder = "MM"
      })
    }

    handleImageLoaded = (event) => {
      const actualWidth = event.target.offsetWidth; // Actual width of the image
      const zoomScale = 0.45; // Zoom level (45%)
      const visibleWidth = actualWidth * zoomScale; // Calculate the visible width with zoom

      this.setState({
        loading: false,
        isVisible: true,
        imageLoaded: true,
        imageWidth: visibleWidth // Use the visible width
      });
    }

    render() {
      const isRaidersFord = process.env.REACT_APP_FIREBASE_PROJECT_ID === 'raidersford';
      const isDev = process.env.REACT_APP_FIREBASE_PROJECT_ID === 'sendprizesdev';
      const isRaidersMX = process.env.REACT_APP_FIREBASE_PROJECT_ID === "raiderssweepsmx";
      const isMlbApp =  process.env.REACT_APP_IS_MLB_TEAM === "true";
      const { redirect } = this.state;
      const tenantVariables = this.props.variables || {};
      const stringConstants = this.props.stringConstants || {};
      let htmlParameterEmail = getURLParameter("email");
      let htmlNextUrl = getURLParameter("nexturl");
      if(htmlParameterEmail && !validateEmail(htmlParameterEmail)){
        htmlParameterEmail = null
      }
      const tenantRules = this.state.tenantRules || {};
      const rulesRegsText = tenantRules.rulesAndRegsText || "I agree to the rules and regs";
      const howToPlayLink = tenantRules.howToPlayLink || "";
      let howToPlayText = tenantRules.howToPlayText || "";
      const rulesInAppButtonText = tenantRules.rulesInAppButtonText || "MLB Privacy Policy";
      let rulesShowInAppPopUpHeader = tenantRules.rulesShowInAppPopUpHeader;
      let rulesShowInAppPopUpText = tenantRules.rulesShowInAppPopUpText;
      const promotionText = tenantVariables.promotionText || "Check To Opt-In To More Offers From Us";
      const promotionTextTwo = tenantVariables.promotionTextTwo || "Check To Opt-In To More Offers From Us";
      const promotionTextThree = tenantVariables.promotionTextThree || "Check To Opt-In To More Offers From Us";
      const promotionTextFour = tenantVariables.promotionTextFour || "Check To Opt-In To More Offers From Us";
      let collectTextQuestion = tenantVariables.collectTextQuestion || "Why are you a great fan?";
      let collectTextInputOneLabel = tenantVariables.collectTextInputOneLabel || "What makes you a great fan?";
      let collectTextInputTwoLabel = tenantVariables.collectTextInputTwoLabel || "What makes you the greatest fan?";
      const verifiedAge = this.props.checkForAgeGate(tenantVariables);
      const yesNoQuestionText = tenantVariables.yesNoQuestionText || "";
      if(!verifiedAge){
        return (
            <Redirect to="/age_gate" />
        )
      }
      if (redirect || this.state.userEmail) {
        let redirectUrl = "/";
        if(isRaidersFord || isDev){
          redirectUrl = "/ford";
        }
        if(htmlNextUrl === "seasonlong"){
          redirectUrl="/seasonlong";
        }
        this.props.setCurrentUser();
        return (
            <Redirect to={redirectUrl} />
        )
      }
      let placeHolderZipCode = tenantVariables.zipCodePlaceholder || stringConstants.PLACEHOLDERZIPCODETEXT;
      let streetPlaceholder = tenantVariables.streetPlaceholder || stringConstants.PLACEHOLDERSTREETTEXT;
      let cityPlaceholder = tenantVariables.cityPlaceholder || stringConstants.PLACEHOLDERCITYTEXT;
      let statePlaceholder = tenantVariables.statePlaceholder || stringConstants.PLACEHOLDERSTATETEXT;
      let placeHolderAddress = tenantVariables.fullAddressPlaceHolderText || stringConstants.PLACEHOLDERFULLADDRESSTEXT;
      let placeHolderEmail = tenantVariables.emailPlaceHolderText || stringConstants.PLACEHOLDEREMAILTEXT;
      let placeHolderOtherEmail = tenantVariables.otherEmailPlaceHolderText || stringConstants.PLACEHOLDEREMAILTEXT;
      let placeHolderName = tenantVariables.namePlaceHolderText || stringConstants.PLACEHOLDERNAMETEXT;
      let placeHolderOtherName = tenantVariables.otherNamePlaceHolderText || stringConstants.PLACEHOLDERNAMETEXT;
      let placeHolderPhoneNumber = tenantVariables.phoneNumberPlaceholderText || stringConstants.PLACEHOLDERPHONENUMBERTEXT;
      let placeHolderBirthdayText = tenantVariables.birthdayPlaceHolderText || stringConstants.PLACEHOLDERBIRTHDAYTEXT;
      let placeHolderSocialName = tenantVariables.socialHandlePlaceHolderText || stringConstants.PLACEHOLDERSOCIALTEXT;
      const customDropdownPlaceholderText = tenantVariables.customDropdownPlaceholderText;
      let howYouHeardPlaceHolderText = tenantVariables.howYouHeardPlaceHolderText || stringConstants.PLACEHOLDERHOWYOUHEARDTEXT;
      const yesExplanationPlaceholder = tenantVariables.yesExplanationPlaceHolderText || stringConstants.PLACEHOLDERYESEXPLANATIONTEXT;
      let frontLogo = tenantVariables.frontLogoImage;
      const aboveFormText = tenantVariables.aboveFormText;
      const aboveSubmitButtonText = tenantVariables.aboveSubmitButtonText;
      const belowSubmitButtonText = tenantVariables.belowSubmitButtonText;
      const primaryColor = tenantVariables.primaryColor || "black";
      const secondaryColor = tenantVariables.secondaryColor || "white";
      const logOutButtonColor = tenantVariables.logOutButtonColor || "white";
      const rulesPopUpHeader = tenantRules.rulesPopUpHeader || "Rules & Regs";
      const rulesPopUpText = tenantRules.rulesPopUpText || "";
      const supportHeader = tenantRules.supportHeader || "NEED SUPPORT?";
      const customOptions = detectHowManyCodesEntered(tenantVariables.customDropdownOptions, false, true);
      const supportText = tenantRules.supportText || "Email customerservice@website.mlb.com with your issue and we'll be in touch!";
      let showHowToPlayButton = false;
      if(howToPlayText || howToPlayLink){
        showHowToPlayButton = true
      }
      const noEmailCollection = tenantVariables.doNotCollectEmail || isMlbApp;
      if(isRaidersMX){
        placeHolderEmail = "Escribe tu correo electrónico";
        placeHolderName = "Escribe tu nombre completo";
        placeHolderPhoneNumber = "Escribe tu número celular";
        placeHolderZipCode = "Escribe tu código postal";
      }
      const spanStyles = {
        color: logOutButtonColor,
        fontFamily: "Oswald",
        display: 'inline-block', // Ensures the maxWidth is respected
        maxWidth: this.state.imageWidth, // Makes sure the span does not exceed its parent's width
        whiteSpace: 'normal' // Allows text to wrap
      };

      return(
          <div
              className="flex-container-home"
              style={{
                backgroundImage: `url(${tenantVariables.backgroundImage})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                backgroundAttachment: 'fixed'
              }}
          >
            <div style={{display: this.state.loading ? 'block' : 'none' }}>
              <Loading loading={this.state.loading} color={primaryColor}/>
            </div>
            <div className="flex-content-container-home">
              <div className="intro-container-home fade-in-image">
                <div className="flex-header-home" style={{marginTop:10, marginBottom: 10}}>
                  <div style={{display: isMlbApp? "block":"none", textAlign: "left", fontFamily: "Oswald"}}>
                    <button className="btn btn-logout" onClick={() => { this.toggleSupport() }} style={{color: logOutButtonColor, borderColor: logOutButtonColor}}>{stringConstants.SUPPORTTEXT}</button>
                  </div>
                  <div style={{display: isMlbApp? "":"none", textAlign:"right", fontFamily: "Oswald"}}>
                    <button className="btn btn-logout" onClick={() => { this.toggleMlbRules() }} style={{color: logOutButtonColor, borderColor: logOutButtonColor}}>{rulesInAppButtonText}</button>
                  </div>
                </div>
                <div className="hero-text-container" style={{paddingTop:0, visibility: this.state.loading && 'hidden'}}>
                  <img onLoad={this.handleImageLoaded}  onError={()=>this.setState({imageLoaded:true})} src={frontLogo} className="main-hero-image" alt=""/>
                </div>
                { !this.state.loading &&
                    <div id="h" style={{maxWidth: this.state.imageWidth}}>
                      <span style={spanStyles} dangerouslySetInnerHTML={{ __html:aboveFormText}}/>
                    </div>
                }
                {this.state.imageLoaded &&
                  <div className="container-out" style={{display: this.state.loading ? 'none' : ''}}>
                    <div className="question-box question-form" style={{padding:'15px', maxWidth: 350, margin: "auto"}}>
                      {tenantVariables.loginFormHeader &&
                          <h4 style={{fontSize: "1.5rem", fontFamily: "Oswald", fontWeight: 700, display: htmlParameterEmail || noEmailCollection ? "none":""}}>{tenantVariables.loginFormHeader}</h4>
                      }
                      <form onSubmit={this.handleSubmit} id="user-values-form">
                        <div className="input-group" style={{display: tenantVariables.collectName ? '' : "none"}}>
                          <input id="name" name="inputtedName" type="text" style={{fontFamily: "Oswald", borderRadius: "0.25rem"}} className="form-control" onChange={this.handleChange} placeholder={placeHolderName} />
                        </div>
                        <div className="input-group" style={{display: htmlParameterEmail || noEmailCollection ? "none":""}}>
                          <div style={{width:"100%", height:10}}/>
                          <input id="email" name="inputtedEmail" type="email" style={{fontFamily: "Oswald", borderRadius: "0.25rem"}} className="form-control" onChange={this.handleChange} placeholder={placeHolderEmail} />
                        </div>
                        <div className="input-group" style={{display: tenantVariables.collectOtherEmail ? "":"none"}}>
                          <div style={{width:"100%", height:10}}/>
                          <input id="inputtedOtherEmail" name="inputtedOtherEmail" type="email" style={{fontFamily: "Oswald", borderRadius: "0.25rem"}} className="form-control" onChange={this.handleChange} placeholder={placeHolderOtherEmail} />
                        </div>
                        <div className="input-group" style={{display: tenantVariables.collectOtherName ? '' : "none"}}>
                          <div style={{width:"100%", height:10}}/>
                          <input id="inputtedOtherName" name="inputtedOtherName" type="text" style={{fontFamily: "Oswald", borderRadius: "0.25rem"}} className="form-control" onChange={this.handleChange} placeholder={placeHolderOtherName} />
                        </div>
                        <div className="input-group" style={{display: tenantVariables.collectPhoneNumber ? '' : "none"}}>
                          <div style={{width:"100%", height:10}}/>
                          <input id="phoneNumber" name="inputtedPhoneNumber" type="tel" style={{fontFamily: "Oswald", borderTopLeftRadius: "0.25rem", borderBottomLeftRadius:"0.25rem"}} className="form-control" onChange={this.handleChange} placeholder={placeHolderPhoneNumber} />
                        </div>
                        <div className="input-group" style={{display: tenantVariables.collectStreet ? '' : "none"}}>
                          <div style={{width:"100%", height:10}}/>
                          <input id="inputtedStreet" name="inputtedStreet" type="text" style={{fontFamily: "Oswald", borderTopLeftRadius: "0.25rem", borderBottomLeftRadius:"0.25rem"}} className="form-control" onChange={this.handleChange} placeholder={streetPlaceholder} />
                        </div>
                        <div className="input-group" style={{display: tenantVariables.collectCity ? '' : "none"}}>
                          <div style={{width:"100%", height:10}}/>
                          <input id="inputtedCity" name="inputtedCity" type="text" style={{fontFamily: "Oswald", borderTopLeftRadius: "0.25rem", borderBottomLeftRadius:"0.25rem"}} className="form-control" onChange={this.handleChange} placeholder={cityPlaceholder} />
                        </div>
                        <div className="input-group" style={{display: tenantVariables.collectState ? '' : "none"}}>
                          <div style={{width:"100%", height:10}}/>
                          <input id="inputtedState" name="inputtedState" type="text" style={{fontFamily: "Oswald", borderTopLeftRadius: "0.25rem", borderBottomLeftRadius:"0.25rem"}} className="form-control" onChange={this.handleChange} placeholder={statePlaceholder} />
                        </div>
                        <div className="input-group" style={{display: tenantVariables.collectZipCode ? '' : "none"}}>
                          <div style={{width:"100%", height:10}}/>
                          <input id="zipCode" name="inputtedZipCode" type="text" style={{fontFamily: "Oswald", borderRadius: "0.25rem"}} className="form-control" onChange={this.handleChange} placeholder={placeHolderZipCode} />
                        </div>
                        <div className="input-group" style={{display: tenantVariables.collectFullAddress ? '' : "none"}}>
                          <div style={{width:"100%", height:10}}/>
                          <input id="fullAddress" name="inputtedFullAddress" type="text" style={{fontFamily: "Oswald", borderTopLeftRadius: "0.25rem", borderBottomLeftRadius:"0.25rem"}} className="form-control" onChange={this.handleChange} placeholder={placeHolderAddress} />
                        </div>
                        <div className="input-group" style={{display: tenantVariables.collectBirthday && tenantVariables.formBirthday ? '' : 'none', border: "1px solid #ced4da", borderRadius: "0.25rem", marginTop: 10}}>
                          <input value={this.state.month} onKeyUp={(e)=>e.target.value && e.target.value.length === 2 && document.getElementById("day").focus()} name="month" id="month" onChange={(e)=> this.handleDateChange(e)} className="form-control" type="text" style={{border:"none",fontFamily: "Oswald"}} onFocus={(e) => this.handleBirthdayFocus()} placeholder={placeHolderBirthdayText} maxLength="2" pattern="\d*"/>
                          {this.state.birthdayFocused &&
                            <>
                              <span style={{display: this.state.year || this.state.day || this.state.month?"":"none", alignSelf:"center"}}>/</span>
                              <input value={this.state.day} onKeyUp={(e)=>e.target.value && e.target.value.length === 2 && document.getElementById("year").focus()} name="day" id="day" className="form-control" type="text" style={{border:"none",fontFamily: "Oswald"}} onChange={(e)=> this.handleDateChange(e)} onFocus={(e) => this.handleBirthdayFocus()} maxLength="2" pattern="\d*"/>
                              <span style={{display: this.state.year || this.state.day || this.state.month?"":"none", alignSelf:"center"}}>/</span>
                              <input value={this.state.year} name="year" id="year" className="form-control" type="text" style={{border:"none",fontFamily: "Oswald"}} onFocus={(e) => this.handleBirthdayFocus()} maxLength="4" pattern="\d*" onChange={(e)=> this.handleDateChange(e)}/>
                            </>
                          }
                        </div>
                        {tenantVariables.collectYesNoQuestion &&
                            <div className="agree_to_rules_class" style={{textAlign: "left", marginBottom: 20, fontFamily: "Oswald"}} onChange={this.handleChange}>
                              <p className="text-muted2" dangerouslySetInnerHTML={{ __html:yesNoQuestionText}}/>
                              <input type="radio" value="no" name="yesNoQuestionAnswer" checked={this.state.yesNoQuestionAnswer === "no"}/> <span style={{marginRight:10}}>{stringConstants.NOTEXT}</span>
                              <input type="radio" value="yes" name="yesNoQuestionAnswer" checked={this.state.yesNoQuestionAnswer === "yes"}/> <span style={{marginRight:10}}>{stringConstants.YESTEXT}</span>
                            </div>
                        }
                        {tenantVariables.collectYesExplanation && this.state.yesNoQuestionAnswer === "yes" &&
                            <div className="input-group">
                              <div style={{width:"100%", height:10}}/>
                              <textarea rows="7" id="yesExplanationInput" name="yesExplanationInput" style={{fontFamily: "Oswald", borderRadius: "0.25rem"}} className="form-control" onChange={this.handleChange} placeholder={yesExplanationPlaceholder} />
                            </div>
                        }
                        <div className="input-group" style={{display: tenantVariables.collectSocialHandle ? '' : "none"}}>
                          <div style={{width:"100%", height:10}}/>
                          <input id="socialHandle" name="inputtedSocialHandle" type="text" style={{fontFamily: "Oswald", borderRadius: "0.25rem"}} className="form-control" onChange={this.handleChange} placeholder={placeHolderSocialName} />
                        </div>
                        <div className="input-group" style={{display: tenantVariables.collectHowYouHeard ? '' : "none"}}>
                          <div style={{width:"100%", height:10}}/>
                          <input id="howYouHeard" name="howYouHeard" type="text" style={{fontFamily: "Oswald", borderRadius: "0.25rem"}} className="form-control" onChange={this.handleChange} placeholder={howYouHeardPlaceHolderText} />
                        </div>
                        <div className="input-group" style={{display: tenantVariables.collectCustomDropdown ? '' : "none"}}>
                          <div style={{width:"100%", height:10}}/>
                          <select onChange={this.handleChange} className="form-control" id="customDropdownInput" name="customDropdownInput" style={{fontFamily: "Oswald", borderRadius: "0.25rem"}}>
                            <option key="-1" value="">{customDropdownPlaceholderText}</option>
                            {customOptions.map((option, index) => (
                                <option key={index} value={option}>
                                  {option}
                                </option>
                            ))}
                          </select>
                        </div>
                        <div className="input-group" style={{display: tenantVariables.collectTextInputOne ? '' : "none"}}>
                          <div style={{width:"100%", height:10}}/>
                          <input id="textInputOne" name="textInputOne" type="text" style={{fontFamily: "Oswald", borderRadius: "0.25rem"}} className="form-control" onChange={this.handleChange} placeholder={collectTextInputOneLabel} />
                        </div>
                        <div className="input-group" style={{display: tenantVariables.collectTextInputTwo ? '' : "none"}}>
                          <div style={{width:"100%", height:10}}/>
                          <input id="textInputTwo" name="textInputTwo" type="text" style={{fontFamily: "Oswald", borderRadius: "0.25rem"}} className="form-control" onChange={this.handleChange} placeholder={collectTextInputTwoLabel} />
                        </div>
                        <div className="input-group" style={{display: tenantVariables.collectTextInput ? '' : "none"}}>
                          <div style={{width:"100%", height:10}}/>
                          <textarea rows="7" id="fanResponseToCollectTextInput" name="fanResponseToCollectTextInput" style={{fontFamily: "Oswald", borderRadius: "0.25rem"}} className="form-control" onChange={this.handleChange} placeholder={collectTextQuestion} />
                        </div>
                        <div className="input-group" style={{ display: tenantVariables.collectPhoto ? '' : "none" }}>
                          <div style={{ width: "100%", height: 10 }} />
                          {tenantVariables.photoInputLabelText &&
                            <div style={{fontFamily: "Oswald", marginRight: 10}}>
                              {tenantVariables.photoInputLabelText}
                            </div>
                          }
                          <input
                              id="photoToSave"
                              name="photoToSave"
                              type="file"
                              accept="image/jpeg, image/png, image/jpg, image/webp"
                              capture="environment" // or use "user" for front camera
                              style={{ display: 'none' }} // Hide the default input
                              onChange={this.handleChange}
                              ref={this.fileInputRef} // Reference to the input
                          />
                          <button
                              type="button"
                              onClick={() => this.fileInputRef.current.click()} // Trigger file input on button click
                              style={{ fontFamily: "Oswald", borderRadius: "0.25rem", cursor: "pointer" }}
                          >
                            {stringConstants.UPLOADPHOTOBUTTONTEXT || "Upload Photo"}
                          </button>
                        </div>
                        <div className="form-check" style={{display:!tenantVariables.noMandatoryTermsAndConditions? 'block':'none', fontFamily: "Oswald", marginTop: 10, textAlign: 'left'}}>
                          <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" ref="agree_to_rules_regs"/>
                          <span id="rulesAndRegsTextContainer" onClick={()=>{(isMlbApp || rulesPopUpText) && this.toggleRules()}} dangerouslySetInnerHTML={{ __html: rulesRegsText}}/>
                        </div>
                        <div className="form-check" style={{display:tenantVariables.collectOptIn? 'block':'none', fontFamily: "Oswald", marginTop: 10, textAlign: 'left'}}>
                          <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" ref="agree_to_promotion" defaultChecked={!tenantVariables.optInDefaultUnchecked && tenantVariables.collectOptIn}/>
                          <label className="form-check-label" htmlFor="flexCheckDefault" dangerouslySetInnerHTML={{ __html: promotionText}}/>
                        </div>
                        <div className="form-check" style={{display:tenantVariables.collectOptInTwo? 'block':'none', fontFamily: "Oswald", marginTop: 10, textAlign: 'left'}}>
                          <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" ref="agree_to_promotion_two" defaultChecked={!tenantVariables.collectOptInTwoNotDefaultCheck && tenantVariables.collectOptInTwo}/>
                          <label className="form-check-label" htmlFor="flexCheckDefault" dangerouslySetInnerHTML={{ __html: promotionTextTwo}}/>
                        </div>
                        <div className="form-check" style={{display:tenantVariables.collectOptInThree? 'block':'none', fontFamily: "Oswald", marginTop: 10, textAlign: 'left'}}>
                          <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" ref="agree_to_promotion_three" defaultChecked={!tenantVariables.collectOptInThreeNotDefaultCheck && tenantVariables.collectOptInThree}/>
                          <label className="form-check-label" htmlFor="flexCheckDefault" dangerouslySetInnerHTML={{ __html: promotionTextThree}}/>
                        </div>
                        <div className="form-check" style={{display:tenantVariables.collectOptInFour? 'block':'none', fontFamily: "Oswald", marginTop: 10, textAlign: 'left'}}>
                          <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" ref="agree_to_promotion_four" defaultChecked={!tenantVariables.collectOptInFourNotDefaultCheck && tenantVariables.collectOptInFour}/>
                          <label className="form-check-label" htmlFor="flexCheckDefault" dangerouslySetInnerHTML={{ __html: promotionTextFour}}/>
                        </div>
                        <div style={{display:tenantVariables.aboveSubmitButtonText? 'block':'none', fontFamily: "Oswald", marginTop: 10, textAlign: 'center'}}>
                          <span dangerouslySetInnerHTML={{ __html:aboveSubmitButtonText}}/>
                        </div>
                        <button style={{backgroundColor: tenantVariables.primaryColor, color: tenantVariables.secondaryColor}} className="btn btn-play" id="submitButton"><strong>{stringConstants.SUBMITBUTTONTEXT}</strong></button>
                        {isRaidersFord &&
                            <div style={{marginTop: '1rem'}}>
                              <p style={{fontFamily: "Oswald", fontSize: '.75rem'}}>
                                *See Owner’s Manual for important operating instructions.
                              </p>
                            </div>
                        }
                        <div style={{display:tenantVariables.belowSubmitButtonText? 'block':'none', fontFamily: "Oswald", marginTop: 10, textAlign: 'center'}}>
                          <span dangerouslySetInnerHTML={{ __html:belowSubmitButtonText}}/>
                        </div>
                      </form>
                      <div style={{display: tenantVariables.allowAnonymousLogin ? "" : "none"}}>
                        <button style={{backgroundColor: primaryColor, color: secondaryColor}} className="btn btn-play" id="skipButton" onClick={()=>this.skipLogin()}><strong>SKIP</strong></button>
                      </div>
                      <button style={{display: showHowToPlayButton?"":"none", backgroundColor: primaryColor, color: secondaryColor}} className="btn btn-rules" onClick={()=>{howToPlayText? this.toggle():window.open(howToPlayLink, '_blank');}}><strong>{stringConstants.HOWTOPLAYTEXT}</strong></button>
                    </div>
                  </div>
                  }
              </div>
              {(isRaidersMX || isDev) &&
                  <div style={{textAlign: "center", color: "white", fontFamily: "Oswald"}}>
                    <div style={{maxWidth: 350, padding: 15, margin: "auto"}}>
                      <p style={{textAlign: 'left'}}>
                        No olvides estar pendiente de nuestras redes sociales oficiales para más noticias del equipo.
                      </p>
                      <div style={{fontSize: 24, textDecoration: "underline", marginBottom: 10}}>
                        <a target="_blank" rel="noopener" style={{color:"white"}} href="https://www.facebook.com/LosRaidersOficial">Facebook.com/LosRaidersOficial</a>
                        <br/>
                        <a target="_blank" rel="noopener" style={{color:"white"}} href="https://www.instagram.com/losraidersoficial">Instagram (@losraidersoficial)</a>
                        <br/>
                        <a target="_blank" rel="noopener" style={{color:"white"}} href="https://www.youtube.com/@LosRaidersOficial">YouTube Los Raiders</a>
                      </div>
                    </div>
                  </div>
              }
            </div>
            <Modal isOpen={this.state.modal} toggle={this.toggle} style={{width: '90%'}} id="myModal">
              <ModalHeader/>
              <ModalBody style={{textAlign:"center"}}>
                <div className="container-out" style={{paddingLeft: 20, paddingRight: 20}}>
                  <span dangerouslySetInnerHTML={{ __html:howToPlayText}}/>
                  <button className="btn btn-play" onClick={() => { this.toggle(); }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'20px', fontSize:'1.3 rem'}}>{stringConstants.OKTEXT}</button>
                </div>
              </ModalBody>
              <ModalFooter style={{borderTop:'none'}}/>
            </Modal>
            <Modal isOpen={this.state.modalRules} style={{width: '90%'}} id="rulesModal">
              <ModalHeader style={{color: secondaryColor, padding:10}}>
                <span style={{fontSize:25}}>
                  {rulesPopUpHeader}
                </span>
              </ModalHeader>
              <ModalBody>
                <center className="container-out">
                  <div className="question-box question-form">
                    <p style={{width:"100%",height:200, overflow:"scroll",padding:10, borderBottom:"1px solid black", borderTop: "1px solid black", fontWeight:100, fontSize:14}} dangerouslySetInnerHTML={{ __html:rulesPopUpText}}/>
                    <button className="btn btn-default btn-admin btn-cancel" onClick={() => { this.toggleRules(); }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'20px', fontSize:'1.3 rem'}}>{stringConstants.DISMISSTEXT}</button>
                  </div>
                </center>
              </ModalBody>
            </Modal>
            <Modal isOpen={this.state.modalMlbRules} style={{width: '90%'}} id="rulesModal">
              <ModalHeader style={{color: secondaryColor, padding:10}}>
                <span style={{fontSize:25}}>
                    {rulesShowInAppPopUpHeader}
                </span>
              </ModalHeader>
              <ModalBody>
                <center className="container-out">
                  <div className="question-box question-form">
                    <p style={{width:"100%",height:200, overflow:"scroll",padding:10, borderBottom:"1px solid black", borderTop: "1px solid black", fontWeight:100, fontSize:14}} dangerouslySetInnerHTML={{ __html:rulesShowInAppPopUpText}}/>
                    <button className="btn btn-default btn-admin btn-cancel" onClick={() => { this.toggleMlbRules(); }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'20px', fontSize:'1.3 rem'}}>{stringConstants.DISMISSTEXT}</button>
                  </div>
                </center>
              </ModalBody>
            </Modal>
            <Modal isOpen={this.state.modalSupport} style={{width: '90%'}} id="rulesModal">
              <ModalHeader style={{color: secondaryColor, padding:10}}>
                    <span style={{fontSize:25}}>
                        {supportHeader}
                    </span>
              </ModalHeader>
              <ModalBody>
                <center className="container-out">
                  <div className="question-box question-form">
                    <p style={{width:"100%",overflow:"scroll",fontWeight:100, fontSize:14, marginBottom: 0}} dangerouslySetInnerHTML={{ __html:supportText}}/>
                    <button className="btn btn-default btn-admin btn-cancel" onClick={() => { this.toggleSupport(); }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', fontSize:'1.3 rem'}}>{stringConstants.DISMISSTEXT}</button>
                  </div>
                </center>
              </ModalBody>
            </Modal>
          </div>
      )
    }
}

export default Login;
